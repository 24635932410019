import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import UnauthLayout from 'components/UnauthLayout';
import { Row, Col, Divider, Form } from 'antd';
import { Button, TextFieldPassword, Typography } from 'ui-kit';
import { Controller, useForm } from 'react-hook-form';
import { coHostSetupPassword, useCoHostCheckUUID } from 'api/co-hosts';
import { useParams } from 'react-router-dom';
import Alert from 'components/Alert';

const validSchema = yup.object({
  password: yup.string().required('This field is required'),
  confirmPassword: yup
    .string()
    .test('same-password', `Password doesn't match.`, (value, { parent }) => {
      return parent.password && value === parent.password;
    })
    .required('This field is requires'),
});

interface InitialValues {
  password: string;
  confirmPassword: string;
}

const defaultValues: InitialValues = {
  password: '',
  confirmPassword: '',
};

const CoHostSetPasswordPage = () => {
  const [showSuccess, setShowSuccess] = useState(false);

  const { invitationUUID } = useParams<{ invitationUUID: string }>();

  const { data } = useCoHostCheckUUID({ uuid: invitationUUID });

  const showUUIDError = useMemo(() => !data?.isValid, [data]);

  const { control, handleSubmit } = useForm({
    defaultValues,
    resolver: yupResolver(validSchema),
  });

  const onSubmit = (data) => {
    coHostSetupPassword({ uuid: invitationUUID, password: data.password }).then((res) => {
      if (res.ok) {
        setShowSuccess(true);
      }
    });
  };

  return (
    <UnauthLayout>
      <Row gutter={[0, 16]}>
        <Col xs={24}>
          <div>
            <Typography variant="h6">You are invited as a co-host user. </Typography>
            <Divider />
          </div>
        </Col>
        {showUUIDError && (
          <Col xs={24}>
            <Alert type="error">This invitation is invalid.</Alert>
          </Col>
        )}
        {showSuccess && (
          <>
            <Col xs={24}>
              <Typography variant="body2">The password was set up successfully.</Typography>
            </Col>
            <Col xs={24}>
              <Button to="/login" variant="contained" fullWidth>
                Sign in
              </Button>
            </Col>
          </>
        )}
        {!showSuccess && !showUUIDError && (
          <>
            <Col xs={24}>
              <Typography variant="body2">You should set up your password.</Typography>
            </Col>
            <Col xs={24}>
              <Form name="test" layout="vertical">
                <Row gutter={[0, 16]}>
                  <Col xs={24}>
                    <Controller
                      control={control}
                      name="password"
                      render={({ field: { name, onChange }, fieldState: { error } }) => (
                        <TextFieldPassword
                          label="New Password"
                          name={name}
                          onChange={onChange}
                          required
                          fullWidth
                          error={!!error}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </Col>
                  <Col xs={24}>
                    <Controller
                      control={control}
                      name="confirmPassword"
                      render={({ field: { name, onChange }, fieldState: { error } }) => (
                        <TextFieldPassword
                          label="Confirm Password"
                          name={name}
                          onChange={onChange}
                          required
                          fullWidth
                          error={!!error}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </Col>
                  <Col xs={24}>
                    <Form.Item noStyle shouldUpdate>
                      {() => (
                        <Button
                          variant="contained"
                          type="submit"
                          fullWidth
                          onClick={(data) => handleSubmit(onSubmit)(data)}
                        >
                          Save
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </>
        )}
      </Row>
    </UnauthLayout>
  );
};

export default CoHostSetPasswordPage;
