import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const Partners = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Partners/Partners'
  ),
);
const PartnerLanding = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Partners/PartnerLanding/PartnerLanding'
  ),
);
const OwnerRez = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Partners/PartnerCompanies/OwnerRez/OwnerRezIntegration'
  ),
);

const CreateStores = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    // Used only for OwnerRez stores
    'components/Partners/PartnerCompanies/CreateStores'
  ),
);

const Hospitable = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Partners/PartnerCompanies/Hospitable/HospitableIntegration'
  ),
);

const HospitableConnect = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Partners/PartnerCompanies/HospitableConnect/HospitableConnectIntegration'
  ),
);

const Guesty = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Partners/PartnerCompanies/Guesty/GuestyIntegration'
  ),
);

const Hostaway = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Partners/PartnerCompanies/Hostaway/HostawayIntegration'
  ),
);

const Lodgify = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Partners/PartnerCompanies/Lodgify/LodgifyIntegration'
  ),
);

const PartnerRoutes = () => {
  return (
    <Switch>
      <Route exact path="/overview/partners/" component={Partners} />
      <Route path="/overview/partners/landing/:company?" component={PartnerLanding} />
      <Route path="/overview/partners/createStores/" component={CreateStores} />
      <Route path="/overview/partners/:company?/">
        <Switch>
          <Route path="/overview/partners/ownerrez/" component={OwnerRez} />
          <Route path="/overview/partners/hospitable/" component={Hospitable} />
          <Route path="/overview/partners/hospitableconnect/" component={HospitableConnect} />
          <Route path="/overview/partners/guesty/" component={Guesty} />
          <Route path="/overview/partners/hostaway/" component={Hostaway} />
          <Route path="/overview/partners/lodgify/" component={Lodgify} />
        </Switch>
      </Route>
      <Route>
        <Redirect to="/overview/partners/" />
      </Route>
    </Switch>
  );
};

export default PartnerRoutes;
