import useSWR from 'swr';
import { get, post } from './api';
import { ECoHostStatus } from 'components/CoHosts/constants';

export interface ICoHost {
  id: number;
  email: string;
  status: ECoHostStatus;
}

export const useCoHostList = () => {
  const key = `/services/co-host/list/`;
  const { data, error, mutate } = useSWR(key, () => get<Array<ICoHost>>(key));

  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error || !data?.ok,
    mutate,
  };
};

export const coHostCreate = ({ email }: { email: string }) =>
  post<Promise<ICoHost>>(`/services/co-host/create/`, { email });

export const coHostRemove = ({ id }: { id: number }) =>
  post<Promise<void>>(`/services/co-host/${id}/remove/`);

export const useCoHostCheckUUID = ({ uuid }: { uuid: string }) => {
  const key = `/services/co-host/${uuid}/check-uuid/`;
  const { data, error, mutate } = useSWR(key, () => get<{ isValid: boolean }>(key));

  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error || !data?.ok,
    mutate,
  };
};

export const coHostSetupPassword = ({ uuid, password }: { uuid: string; password: string }) =>
  post<Promise<void>>(`/services/co-host/${uuid}/setup-password/`, { password });

export const coHostResendInvitation = ({ id }: { id: number }) =>
  post<Promise<void>>(`/services/co-host/${id}/resend-invitation/`);
